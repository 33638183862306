import React from "react";
import styled from "styled-components";

interface HeaderBarProps {
  white: boolean;
}

const HeaderBarDiv = styled.div`
  height: 5.5vw;
  max-height: 134.4px;
  margin-top: -1px;
  width: 100vw;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 100%
  );

  &.white {
    background: #fff;
  }
`;

const HeaderBar: React.FC<HeaderBarProps> = ({ white }) => (
  <HeaderBarDiv className={white ? "white" : ""}></HeaderBarDiv>
);

export default HeaderBar;
