import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

const ToggleDiv = styled.div`
  width: 14px;
  height: 6px;
  margin: 2px auto;
  background: url("/assets/gestaltungselemente/akkordeon-toggle.svg");
  background-size: 100%;
  background-repeat: no-repeat;

  &.active {
    background: url("/assets/gestaltungselemente/akkordeon-toggle-active.svg");
  }
  @media screen AND (min-width: ${breakpoints.tablet}px) {
    width: 23.33px;
    height: 10px;
    margin-top: 3px;
  }

  @media screen AND (min-width: ${breakpoints.tabletXL}px) {
    width: 33.33px;
    height: 15px;
    margin-top: 4px;
  }
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    width: 35px;
    height: 15px;
    margin-top: 5px;
  }
`;

const Toggle = () => <ToggleDiv title="display/hide content"></ToggleDiv>;

export default Toggle;
