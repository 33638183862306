import React from "react";
import styled from "styled-components";
import { breakpoints, isBrowser } from "../../styles/variables";
import LinkButton from "../Buttons/LinkButton";
import CenteredTextBlock from "../CenteredTextBlock";
import GridImage from "../Layout/GridImage";
import ThreeGrid from "../Layout/Grids/akkordeon/ThreeGrid";
import TwoThirdsGrid from "../Layout/Grids/akkordeon/TwoThirdsGrid";
import YouTubeEmbed from "../YouTubeEmbed";
import {
  lightboxOpenGlobal,
  lightboxContentGlobal,
  lightboxIndexGlobal,
} from "../../layouts/default";
import ImageCarousel from "../ImageCarousel";

interface inhalt {
  title: string;
  normalText: string;
  videosImagesAnwendungenItem: [
    {
      bild: [
        {
          url: string;
          title: string;
        }
      ];
      videoUrl: string;
    }
  ];
}

interface AkkordeonContentProps {
  isOpen: boolean;
  inhalt: inhalt;
}

const ContentDiv = styled.article`
  width: 100%;
  background: white;
  overflow: hidden;
  height: auto;
  max-height: 0px;
  transition: max-height 300ms;

  &.isOpen {
    max-height: 6000px;
    margin-bottom: 12.5vw;
  }

  @media screen AND (min-width: ${breakpoints.tablet}) {
    &.isOpen {
      max-height: 1500px;
    }
  }
`;

const AkkordeonContent: React.FC<AkkordeonContentProps> = ({
  isOpen,
  inhalt,
  index,
}) => {
  const [lightboxIsOpen, setLightboxIsOpen] = lightboxOpenGlobal();
  const [lightboxContent, setLightboxContent] = lightboxContentGlobal();
  const [lightboxIndex, setLightboxIndex] = lightboxIndexGlobal();

  const openLightbox = (items, index) => {
    setLightboxContent(items);
    setLightboxIndex(index);
    actuallyOpenLightbox();
  };

  const actuallyOpenLightbox = () => {
    setLightboxIsOpen(true);
  };

  const videoOrImage = (items) => {
    return items.map((item, index) => {
      return index > 2 ? (
        ""
      ) : item.bild ? (
        <div
          onClick={() => openLightbox(items, index)}
          className="hoverPointer"
          key={index}
        >
          <GridImage img={item.bild[0]} />
        </div>
      ) : item.videoUrl ? (
        <div
          onClick={() => openLightbox(items, index)}
          className="hoverPointer"
          key={index}
        >
          <YouTubeEmbed url={item.videoUrl} prev />
        </div>
      ) : (
        ""
      );
    });
  };

  const inhaltFun = (content: inhalt) => {
    if (content.videosImagesAnwendungenItem && content.videosImagesAnwendungenItem.length == 1) {
      return (
        <ThreeGrid one>
          {videoOrImage(content.videosImagesAnwendungenItem)}
        </ThreeGrid>
      );
    } else if (content.videosImagesAnwendungenItem && content.videosImagesAnwendungenItem.length >= 2 && isBrowser) {
      return (
        <>
          {isOpen ? (
            <ImageCarousel
              setLightboxIsOpen={setLightboxIsOpen}
              setLightboxContent={setLightboxContent}
              setLightboxIndex={setLightboxIndex}
              inhalt={content.videosImagesAnwendungenItem}
              index={index}
            />
          ) : (
            ""
          )}
        </>
      );
    } else {
      return;
    }
  };
  return (
    <ContentDiv className={isOpen ? "isOpen" : ""}>
      <CenteredTextBlock
        inhalt={inhalt.normalText}
        lessBottomSpacing
      ></CenteredTextBlock>
      {inhalt && inhalt.downloadLinkButton && inhalt.downloadLinkButton.length > 0 ? (
        inhalt.downloadLinkButton[0].buttonLink ? (
          <LinkButton
            text={inhalt.downloadLinkButton[0].buttonText}
            link={inhalt.downloadLinkButton[0].buttonLink}
            title={inhalt.downloadLinkButton[0].buttonText}
          />
        ) : (
          <LinkButton
            text={inhalt.downloadLinkButton[0].buttonText}
            link={inhalt.downloadLinkButton[0].buttonLink}
            title={inhalt.downloadLinkButton[0].buttonText}
            download
          />
        )
      ) : (
        ""
      )}
      {inhaltFun(inhalt)}
    </ContentDiv>
  );
};

export default AkkordeonContent;
