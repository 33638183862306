import React from "react";
import styled from "styled-components";
import { superScriptRHelperHTML } from "../../data/superscriptHelper";
import { breakpoints, colors } from "../../styles/variables";

interface LinkButtonProps {
  link: string;
  text: string;
  lessSpacingTop?: boolean;
  styleObj: {};
  download?: boolean;
}

const ButtonA = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 9px 8px 9px;
  font: 18px / 26px "Oswald Regular";
  background: ${colors.green};
  color: white;
  width: auto;
  width: max-content;
  margin: 0 auto 60px;

  &.lessSpacingTop {
    margin-top: -15px;
  }

  &.lessSpacingBottom {
    margin-bottom: 20px;
  }

  img {
    margin: auto 0;
    margin-left: 15px;
    height: 21.27px;
    width: 9.12px;
  }

  @media screen and (min-width: ${breakpoints.tablet}px) {
    padding: 8px 11px 9px 11px;
    font: 24px / 36px "Oswald Regular";

    &.lessSpacingTop {
      margin-top: -25px;
      margin-bottom: 70px;
    }

    &.lessSpacingBottom {
      margin-bottom: 20px;
    }

    img {
      margin-left: 20px;
      height: 26.87px;
      width: 11.52px;
    }
  }

  @media screen and (min-width: ${breakpoints.tabletXL}px) {
    padding: 8px 15px 9px 15px;
  }

  @media screen and (min-width: ${breakpoints.desktop}px) {
    padding: 11px 20px 10px 20px;
    font: 32px / 47px "Oswald Regular";

    &.lessSpacingTop {
      margin-top: -35px;
      margin-bottom: 80px;
    }

    img {
      margin-left: 20px;
      height: 35px;
      width: 15px;
    }
  }
`;

//position arrow absolutely
const LinkButton: React.FC<LinkButtonProps> = ({
  link,
  text,
  lessSpacingTop,
  lessSpacingBottom,
  styleObj,
  download,
}) => (
  <>
    {download ? (
      <ButtonA
        href={link}
        className={
          lessSpacingTop && lessSpacingBottom
            ? "lessSpacingTop lessSpacingBottom download"
            : lessSpacingTop
            ? "lessSpacingTop download"
            : lessSpacingBottom
            ? "lessSpacingBottom download"
            : "download"
        }
        style={styleObj}
        dangerouslySetInnerHTML={{
          __html:
            superScriptRHelperHTML(text) +
            '<img src="/assets/gestaltungselemente/button-arrow.svg" />',
        }}
        download={download}
        target="_blank"
        title={text}
      ></ButtonA>
    ) : (
      <ButtonA
        href={link}
        className={
          lessSpacingTop && lessSpacingBottom
            ? "lessSpacingTop lessSpacingBottom"
            : lessSpacingTop
            ? "lessSpacingTop"
            : lessSpacingBottom
            ? "lessSpacingBottom"
            : ""
        }
        style={styleObj}
        dangerouslySetInnerHTML={{
          __html:
            superScriptRHelperHTML(text) +
            '<img src="/assets/gestaltungselemente/button-arrow.svg" />',
        }}
        title={text}
      ></ButtonA>
    )}
  </>
);

export default LinkButton;
