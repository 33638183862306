import React from "react";
import { graphql } from "gatsby";
import DefaultLayout from "../layouts/default";
import HeaderTitle from "../components/HeaderTitle";
import GreenLead from "../components/GreenLead";
import Akkordeon from "../components/Akkordeon";

interface AnwendungenProps {
  data: {
    cms: {
      entry: {
        title: string;
        greenLead: string;
        siteId: number;
        children: [
          {
            title: string;
            normalText: string;
            videosImagesAnwendungenItem: [
              {
                bild: [
                  {
                    url: string;
                    title: string;
                  }
                ];
                videoUrl: string;
              }
            ];
          }
        ];
      };
    };
  };
}

export const AnwendungenQuery = graphql`
  query ($uri: String!, $siteId: CMS_QueryArgument) {
    cms {
      entry(uri: [$uri], siteId: [$siteId]) {
        title
        siteId
        # localized {
        #   uri
        #   siteId
        # }
        ... on CMS_main_anwendungen_Entry {
          metaBeschreibung
          greenLead
          children {
            title
            ... on CMS_main_anwendungenItem_Entry {
              normalText
              videosImagesAnwendungenItem {
                ... on CMS_videosImagesAnwendungenItem_image_BlockType {
                  bild {
                    url
                    title
                  }
                }
                ... on CMS_videosImagesAnwendungenItem_youtubeVideo_BlockType {
                  videoUrl
                }
              }
              downloadLinkButton {
                ... on CMS_downloadLinkButton_button_BlockType {
                  buttonLink
                  buttonDownload {
                    url
                  }
                  buttonText
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Anwendungen: React.FC<AnwendungenProps> = ({ data }) => (
  <DefaultLayout
    siteId={data.cms.entry.siteId}
    title={data.cms.entry.title}
    //links={data.cms.entry.localized}
    beschreibung={
      data.cms.entry.metaBeschreibung ? data.cms.entry.metaBeschreibung : false
    }
  >
    <HeaderTitle inhalt={data.cms.entry.title} />
    <GreenLead inhalt={data.cms.entry.greenLead} />
    <Akkordeon inhalt={data.cms.entry.children} />
  </DefaultLayout>
);

export default Anwendungen;
