import React from "react";
import styled from "styled-components";
import { superScriptRHelperHTML } from "../../data/superscriptHelper";
import { breakpoints, colors } from "../../styles/variables";
import Toggle from "./Toggle";

interface TrapezoidProps {
  white: boolean;
  isOpen: boolean;
  title: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TrapezoidDiv = styled.div`
  height: 12.5vw;
  width: 50vw;
  margin: 0 auto;
  cursor: pointer;
  position: relative;
  background: url("/assets/gestaltungselemente/akkordeon-trapez.svg");
  background-size: 100%;
  max-width: 960px;
  max-height: 240px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &.white {
    background: url("/assets/gestaltungselemente/footer-and-akkordeon-trapez.svg");
    background-size: 100%;
  }

  &:hover,
  &.isOpen {
    h3 {
      color: ${colors.green};
    }

    > div > div {
      background: url("/assets/gestaltungselemente/akkordeon-toggle-active.svg");
      transform: rotate(180deg);
      background-size: 100%;
    }
  }

  &.isOpen {
    > div > div {
      transform: rotate(0);
    }
  }

  /* &.isOpen:hover {
    transform: rotate(180deg);
  } */

  > div {
    text-align: center;
    margin-top: 3px;
    width: 25vw;
    max-width: 480px;
    max-height: 240px;
  }

  h3 {
    font: 14px / 18px "Oswald Regular";
  }

  > div {
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    > div {
      margin-top: 6px;
    }
    h3 {
      font: 24px / 36px "Oswald Regular";
    }
  }

  @media screen AND (min-width: ${breakpoints.tabletXL}px) {
    > div {
      margin-top: 8px;
    }
    h3 {
      font: 28px / 41px "Oswald Regular";
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    > div {
      margin-top: 10px;
    }
    h3 {
      font: 32px / 47px "Oswald Regular";
    }
  }
`;
const Trapezoid: React.FC<TrapezoidProps> = ({
  white,
  isOpen,
  setIsOpen,
  title,
}) => (
  <TrapezoidDiv
    onClick={() => setIsOpen(!isOpen)}
    className={
      white && isOpen
        ? "white isOpen"
        : white
        ? "white"
        : isOpen
        ? "isOpen"
        : ""
    }
  >
    <div>
      <h3
        dangerouslySetInnerHTML={{ __html: superScriptRHelperHTML(title) }}
      ></h3>
      <Toggle />
    </div>
  </TrapezoidDiv>
);

export default Trapezoid;
