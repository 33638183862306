import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../../../styles/variables";

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 47vw;
  grid-gap: 22px;
  margin-bottom: 22px;
  width: 100%;
  max-width: 1920px;
  overflow: hidden;

  > div > div {
    width: 100%;
    height: 47vw;
    background-position: center;
  }

  > div:nth-last-of-type(3n) {
    grid-column: span 2;
  }

  &.one {
    > div {
      grid-column: span 2;
    }
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    margin-bottom: 30px;
    padding: 0 4.53vw 0;
    grid-auto-rows: 28vw;

    > div > div {
      height: 30vw;
    }

    > div:nth-last-of-type(3n) {
      grid-column: span 1;
    }

    &.one {
      > div {
        grid-column: span 3;
      }
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    grid-gap: 40px;
    margin: 0 auto 40px;
    padding: 0;
    max-width: 1360px;
  }
`;

const ThreeGrid: React.FC = ({ children, one }) => (
  <GridDiv className={one ? "one" : ""}>{children}</GridDiv>
);

export default ThreeGrid;
