import React, { useState } from "react";
import styled from "styled-components";
import AkkordeonContent from "./AkkordeonContent";
import AkkordeonHeader from "./AkkordeonHeader";

interface AkkordeonItemProps {
  white: boolean;
  inhalt: {
    title: string;
    normalText: string;
    videosImagesAnwendungenItem: [
      {
        bild: [
          {
            url: string;
            title: string;
          }
        ];
        videoUrl: string;
      }
    ];
  };
}

const ItemDiv = styled.div``;

const AkkordeonItem: React.FC<AkkordeonItemProps> = ({
  white,
  inhalt,
  index,
}) => {
  const [isOpen, setIsOpen] = useState(white);

  return (
    <ItemDiv>
      <AkkordeonHeader
        title={inhalt.title}
        white={white}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <AkkordeonContent inhalt={inhalt} isOpen={isOpen} index={index} />
    </ItemDiv>
  );
};

export default AkkordeonItem;
