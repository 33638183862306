import React from "react";
import styled from "styled-components";
import { Background } from "react-imgix";
import { imgix } from "../../data/data";

interface GridImageProps {
  img: {
    url: string;
    title: string;
  };
  logo?: boolean;
}

const GridImageDiv = styled(Background)`
  width: 100%;
  height: 100%;
  &.logo {
    background-repeat: no-repeat;
    background-size: contain !important;
    background-position: center;
  }
`;

const GridImage: React.FC<GridImageProps> = ({ img, logo }) => (
  <GridImageDiv
    src={img.url}
    imgixParams={logo ? imgix.gridLogoImg : imgix.gridImg}
    className={logo ? "imageDiv logo" : "imageDiv"}
    htmlAttributes={{ title: img.title }}
  />
);

export default GridImage;
