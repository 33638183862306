import React from "react";
import styled from "styled-components";
import AkkordeonItem from "./Akkordeon/AkkordeonItem";

interface AkkordeonProps {
  inhalt: [
    {
      title: string;
      normalText: string;
      videosImagesAnwendungenItem: [
        {
          bild: [
            {
              url: string;
              title: string;
            }
          ];
          videoUrl: string;
        }
      ];
    }
  ];
}

const AkkordeonDiv = styled.div`
  background: #fff;
  padding-top: 12.5vw;
`;

const Akkordeon: React.FC<AkkordeonProps> = ({ inhalt }) => (
  <AkkordeonDiv>
    {inhalt.map((item, index) => (
      <AkkordeonItem
        key={index}
        index={index}
        inhalt={item}
        white={index == 0 ? true : false}
      />
    ))}
  </AkkordeonDiv>
);

export default Akkordeon;
