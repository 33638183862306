import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";
import HeaderBar from "./HeaderBar";
import Trapezoid from "./Trapezoid";

interface AkkordeonHeaderProps {
  white: boolean;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
}

const HeaderDiv = styled.div`
  margin-top: -6vw;
  &.white {
    background: ${colors.green};
    width: 100vw;
    margin-top: -12.5vw;
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    margin-top: -7vw;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin-top: -8vw;
  }
`;

const AkkordeonHeader: React.FC<AkkordeonHeaderProps> = ({
  white,
  isOpen,
  setIsOpen,
  title,
}) => (
  <HeaderDiv className={white ? "white" : ""}>
    <Trapezoid
      title={title}
      white={white}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
    <HeaderBar white={white} />
  </HeaderDiv>
);

export default AkkordeonHeader;
